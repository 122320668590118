import { lazy, Suspense } from 'react';
import { hydrate } from 'react-dom';

// Lazy-load the App component
const LazyApp = lazy(() => import('./App'));

// Find the root element
const rootElement = document.getElementById('root');

// Render a fallback UI while the App component is being loaded
const fallbackUI = <div>Loading...</div>;

// Hydrate the lazy-loaded App component into the root element
hydrate(
  <Suspense fallback={fallbackUI}>
    <LazyApp />
  </Suspense>,
  rootElement
);
